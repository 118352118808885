@font-face {
  font-family: "SebastianBobbySlanted";
  src: url("./fonts/SebastianBobbySlanted.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

b {
  color: #3e3b3f;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  font-size: 20px;
  background-color: rgba(250, 248, 247, 1);
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}


:focus:not(:focus-visible) {
  outline: none;
}

img {
  width: 100%;
  height: auto;
}

/* Make sure the elements are rendered consistently */
button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}


a:focus {
  outline: none;
}



/* Navigation */

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Jost', sans-serif;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1300px;
}

.logo img{
  width: 150px;
  height: 150px;
}

.menu-icon, .close-icon {
  cursor: pointer;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.menu-icon img, .close-icon img {
  width: 40px;
  display: block;
}

.menu-icon:hover, .close-icon:hover {
  transform: scale(1.2);
  filter: brightness(0.9);
}

.menu {
  position: fixed;
  top: 0;
  right: -500px;
  width: 500px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  padding-top: 20px;
}

.menu.open {
  right: 0;
}

.close-icon {
  position: absolute;
  right: 30px;
  top: 30px;
}

.menu ul {
  list-style: none;
  padding: 50px 20px 20px;
  margin: 0;
}

.menu ul li a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  font-size: 24px;
}

.menu ul li a:hover {
  background-color: #f1f1f1;
}

.slogan-block {
  text-align: center;
  background-color: rgba(250, 248, 247, 1);
  padding: 20px 0;
  font-family: 'Jost', sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: #333;
}

/* MainContent */


.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  min-height: 70vh;
  background-color: rgb(24, 24, 24);
}

.text-center {
  text-align: center;
}

.content-overlay {
  padding: 20px;
  text-align: center;
}

.main-title {
  color: #fff;
  font-size: 90px;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
}

.main-sub-title {
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
}

.join-button {
  margin-top: 20px;
  font-size: 14px;
  padding: 15px 30px;
  text-transform: uppercase;
  border: none;
  font-weight: 400;
  letter-spacing: 0.15em;
  background-color: rgba(240, 232, 227, 1);
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
}

.join-button:hover {
  background-color: rgba(240, 232, 227, 0.8);
  color: #333;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.join-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.secondary-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: #faf8f7;
  max-width: 1350px;
  margin: auto;
}

.left-image {
  flex: 1;
  min-width: 440px;
  height: 470px;
  background-size: cover;
  background-position: center;
}

.right-text {
  flex: 2;
  padding: 20px;
  text-align: center;
}

.secondary-title {
  color: rgba(172, 142, 118, 1);
  font-size: 60px;
  line-height: 1.2;
  font-family: "SebastianBobbySlanted";
  letter-spacing: 0.05em;
}

.secondary-sub-title {
  color: #000;
  font-size: 50px;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
}

.secondary-description {
  color: #555;
  font-size: 18px;
  line-height: 2.2;
  font-weight: 400;
  margin-top: 20px;
  padding: 0 50px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0,1);
}

.nomination-container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: auto;
  max-width: 1300px;
  padding: 50px;
  gap: 30px;
}

.nomination-text-container {
  text-align: center;
  padding-top: 50px;
}

.nomination-details, .nomination-image {
  flex: 1;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(217, 200, 188, 1);
  border-radius: inherit;
}

.nomination-details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.nomination-image {
  background-size: cover;
  background-position: center;
  align-self: stretch;
}

.nomination-details h1 {
  font-size: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: #333;
  text-align: center;
}

.nomination-details h2 {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 400;
  color: #333;
  text-align: center;
}

.nomination-details p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  text-align: center;
  margin-bottom: 15px;
}

.third-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1350px;
  margin: 0 auto;
}

.third-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Jost', sans-serif;
}

.third-section {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.third-sub-section {
  flex: 1;
  padding: 60px;
  text-align: center;
}

.third-section h2 {
  font-size: 35px;
  margin-bottom: 20px;
  line-height: 1.6;
  color: #333;
  font-weight: 400;
}

.third-section p {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
}

.mission-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  margin: 100px 0px;
  padding: 100px 50px;
  opacity: 0.8;
  position: relative;
}

.mission-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}



.mission-content {
  max-width: 830px;
  background-color: rgba(255, 255, 255, 1);
  padding: 50px;
  position: relative;
  z-index: 1;
}

.mission-title {
  font-size: 48px;
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: 0.08em;
  font-size: 54px;
  text-align: center;
  color: rgba(172, 142, 118, 1);;
  margin-bottom: 20px;
}

.mission-sub-title {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 1);
    text-transform: uppercase;
    line-height: 1.8;
    letter-spacing: 0.1em;
    text-align: center;
    font-family: 'Questrial';
    font-weight: 400;
}

.mission-text {
  font-size: 18px;
  color: #666;
  text-align: center;
  line-height: 1.6;
}

.for-section {
  padding-bottom: 100px;
}

.for-content {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.partner-images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.partner-card {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

}

.partner-image {
  width: 200px;
  height: auto;
  margin: 10px;
}

/* AboutUs */

.about-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  background-color: #faf8f7;
  max-width: 1350px;
  margin: auto;
}

.about-title {
  color: #000;
  text-align: center;
  font-size: 50px;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
}

.about-text {
  margin: 20px 0px;
  text-align: center;
  color: #555;
    font-size: 22px;
    line-height: 2.2;
    font-weight: 400;
    padding: 0 50px;
}

.about-sub-title {
  font-size: 30px;
  margin: 20px 0px;
  text-transform: uppercase;
  color: #333;
  text-align: center;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.nomination-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}

.nomination-list li {
  padding: 10px 0;
  font-size: 22px;
  text-transform: uppercase;
  color: #333;
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding-left: 50px;
  background-image: url('./img/win.png');
  background-size: 40px 40px;
}

.nomination-text {
  text-align: center;
  color: #555;
  font-size: 22px;
  line-height: 2.2;
  font-weight: 400;
  padding: 0 50px;
}

/* Winners */

.win {
  text-align: center;
}

.winners-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 50px 0px;
}


.winners-buttons button {
  margin: 0 10px;
  font-size: 14px;
  padding: 15px 30px;
  text-transform: uppercase;
  border: none;
  font-weight: 400;
  letter-spacing: 0.15em;
  background-color: rgba(240, 232, 227, 1);
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
}

.winners-buttons button:hover {
  background-color: rgba(240, 232, 227, 0.8);
  color: #333;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.winners-buttons button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.win-item {
  width: 800px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 50px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(217, 200, 188, 1);
  border-radius: inherit;
}

.win-card {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.win-title {
  color: #000;
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
}

.win-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 28px;
  letter-spacing: 0.05em;
}

.win-text img{
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.win-sub-text {
  color: #555;
    font-size: 22px;
    line-height: 2.2;
}

/* Jury */

.last-jury {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.jury-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;
}

.last-jury .content-overlay {
  padding: 20px;
  text-align: center;
  color: white;
}

.last-jury .main-sub-title {
  font-size: 24px;
  text-transform: uppercase;
}

.last-jury .main-title {
  font-size: 90px;
  line-height: 1.2;
  letter-spacing: 0.05em;
}

.profile-section {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  padding: 20px;
  max-width: 1100px;
}

.profile-section img{
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin-right: 20px;
}


.profile-text h3 {
  font-size: 35px;
  margin-bottom: 10px;
  line-height: 1.6;
  color: #333;
  font-weight: 400;
}

.profile-text p {
  margin: 10px 0;
  font-size: 18px;
    color: #666;
    line-height: 1.6;
}

.council-members {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.member-card {
  background-color: rgba(255, 255, 255, 1);
  padding: 10px 20px;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-align: center;
  width: 240px;
}


/* PhotoGallery */

.slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slider {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.image {
  width: auto;
  height: 250px;
  flex-shrink: 0;
}

.left-arrow, .right-arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 24px;
  color: white;
  z-index: 10;
  user-select: none;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

/* Contacts */

.contact-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #faf8f7;
  margin: 50px auto;
  border-radius: 8px;

}

.contact-card {
  background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(217, 200, 188, 1);
    border-radius: inherit;
    padding: 20px;
    max-width: 600px;
}

.contact-info {
  font-size: 24px;
  color: #555;
  line-height: 2.2;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  text-align: center;
}

/* Form */

.subscribe-form {
  text-align: center;
  max-width: 600px;
  margin: auto;
  padding-bottom: 100px;
}


.input-group {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  gap: 10px;
}

.input-group input {
  width: 30%;
  background: #ffffff;
  font-size: 15px;
  padding: 12px 20px;
  text-align: center;
  height: 25px;
  border: 1px solid transparent;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.input-group input:focus {
  border: 1px solid rgba(172, 142, 118, 1);
  outline: none;
}

.form-text {
  font-size: 35px;
    margin-bottom: 10px;
    line-height: 1.6;
    color: #333;
    font-weight: 400;
}

.form-button {
  font-size: 15px;
  padding: 15.7px 48px;
  text-transform: uppercase;
  border: none;
  font-weight: 400;
  border: 1px solid transparent;
  letter-spacing: 1.5px;
  background-color: rgba(240, 232, 227, 1);
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
}

.form-button:hover {
  background-color: rgba(240, 232, 227, 0.8);
  color: #333;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.form-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Article List */
.article-list {
  padding: 50px;
  background-color: #faf8f7;
  max-width: 1300px;
  margin: 0 auto;
  text-align: center;
}

.article-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.article-item {
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.article-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.article-item h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.article-item p {
  color: #555;
  font-size: 16px;
  line-height: 1.6;
}

.article-item a {
  text-decoration: none;
}

.article-item a:hover h2 {
  color: #000;
}

/* Article Detail */
.article-detail {
  padding: 50px;
  background-color: #faf8f7;
  max-width: 1300px;
  margin: 0 auto;
  text-align: center;
}

.article-detail .back-link {
  display: block;
  margin-bottom: 20px;
  color: #333;
  text-decoration: none;
}

.article-detail .back-link:hover {
  text-decoration: underline;
}

.article-item .date {
  font-size: 16px;
  color: #999;
  margin-bottom: 10px;
}

.article-detail .date {
  text-align: left;
  padding-bottom: 20px;
  font-size: 20px;
  color: #666;
  line-height: 1.6;
}

.article-item .author {
  font-size: 16px;
  color: #777;
  margin-top: 10px;
}

.article-detail .author {
  font-size: 20px;
  color: #777;
  margin-top: 10px;
  line-height: 1.6;
}


.article-title {
  font-size: 35px;
  margin-bottom: 40px;
  line-height: 1.6;
  color: #333;
  font-weight: 400;
  text-align: center;
}

.article-detail p {
  margin-bottom: 40px;
  font-size: 20px;
  color: #666;
  line-height: 1.6;
}

.article-sub-list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
  text-align: left;
}

.article-sub-list li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 1.6;
  color: #666;
}

.article-sub-list li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(110%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #666;
}

.text-start {
  text-align: start;
}


/* Footer */

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  max-width: 1300px;
  padding: 40px 20px;
}

.footer .left-section,
.footer .right-section {
  display: flex;
  align-items: center;
}

.footer p {
  line-height: 1.8;
    letter-spacing: 0.05em;
    font-size: 14px;
    text-align: left;
}

.footer .left-section {
  flex: 1;
  padding-right: 50px;
}

.footer-logo img{
  width: 100px;

  margin-right: 20px;
}

.footer .right-section {
  flex: 2;
  display: flex;
  justify-content: space-between;
  border-left: 2px solid #ece0d1;
  padding-left: 50px;
}

.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer ul li {
  margin-bottom: 10px;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.15em;
}

.footer ul li a{
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer ul li a:hover, .footer ul li a:focus {
  color: rgba(172, 142, 118, 1);
  transform: scale(1.05);
}


.footer ul li:first-child {
  color: rgba(172, 142, 118, 1);
    letter-spacing: 0.15em;
    font-size: 18px;
    text-align: left;
}

.bold {
  font-weight: 500;
}
